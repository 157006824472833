<template>
  <div>
    <!-- 卡片视图区域 -->
    <el-card>
      <div class="top-btn">
        <div>
          <el-button
            type="primary"
            @click="showAddQualityTestDialog"
          >+ 新增检测数据</el-button>
        </div>
        <div style="margin-left:20px;">
          <el-button
            class="download"
            type="primary"
          ><a
              style="color:#FFFFFF;"
              href="http://file.agricultural.maruiwhu.cn/file/upload/null/2022-02-18/552e75be507b4a17bbeed68bb2596c5e.xlsx"
            >下载模板</a></el-button>
        </div>
        <div style="margin-left:20px;">
          <el-upload
            ref="upload"
            :action="uploadUrl"
            :limit="1"
            :before-upload="beforeUpload"
            accept=".xlsx"
            class="up-class"
            :on-success="uploadSuccess"
            :show-file-list="false"
            :auto-upload="true"
            :headers="headers"
          >
            <el-button type="primary">导入</el-button>
          </el-upload>
        </div>
      </div>
      <!-- 第一行，输入框和添加按钮 -->
      <el-row>
        <div class="input">
          样品名称：
          <!-- 输入框，尾部带按钮 -->
          <el-input
            placeholder="请输入样品名称"
            v-model="queryInfo.productName"
            clearable
            @clear="getQualityTestList"
            @change="searchQualityTestList"
          >
            <el-button
              slot="append"
              @click="searchQualityTestList"
            >搜索</el-button>
          </el-input>
        </div>
      </el-row>
      <!-- 内容主体 -->
      <el-table
        :data="qualityTestList"
        stripe
        style="width: 100%"
        border
      >
        <!-- 第一列id -->
        <el-table-column
          label="id"
          width="100"
          aligin="center"
          prop="id"
        >
        </el-table-column>
        <!-- 第二列食品名称 -->
        <el-table-column
          label="样品名称"
          aligin="center"
          prop="productName"
        >
        </el-table-column>
        <!-- 第三列食品种类 -->
        <el-table-column
          label="食品种类"
          aligin="center"
          prop="productCategoryName"
        >
        </el-table-column>
        <!-- 第四列检测项目 -->
        <el-table-column
          label="检测项目"
          aligin="center"
          prop="qualityStandardVo.projectName"
        >
        </el-table-column>
        <!-- 第五列检测值 -->
        <el-table-column
          label="检测值"
          aligin="center"
          prop="qualityTestValue"
        >
        </el-table-column>
        <!-- 第六列检测结果 -->
        <el-table-column
          label="检测结果"
          aligin="center"
        >
          <template slot-scope="scope">
            <el-tag
              type="success"
              v-if="scope.row.result === 0"
            >合格</el-tag>
            <el-tag
              type="danger"
              v-if="scope.row.result === 1"
            >不合格</el-tag>
          </template>
        </el-table-column>
        <!-- 第七列检测员 -->
        <el-table-column
          label="检测员"
          aligin="center"
          prop="surveyor"
        >
        </el-table-column>
        <!-- 第八列国家标准值 -->
        <el-table-column
          label="国家标准值"
          aligin="center"
          prop="qualityStandardVo.standard"
        >
        </el-table-column>
        <!-- 第九列国家标准号 -->
        <el-table-column
          label="国家标准号"
          aligin="center"
          prop="qualityStandardVo.standardNumber"
        >
        </el-table-column>
        <!-- 第十列操作 -->
        <el-table-column
          label="操作"
          width="200"
          aligin="center"
        >
          <!-- 修改按钮 -->
          <template slot-scope="scope">
            <el-button
              size="mini"
              type="primary"
              @click="showEditQualityTestDialog(scope.row)"
            >修改</el-button>
            <!-- 删除按钮 -->
            <el-button
              size="mini"
              type="danger"
              @click="removeQualityTestById(scope.row.id)"
            >删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryInfo.pageNo"
        :page-sizes="[15, 30, 50, 100]"
        :page-size="queryInfo.limit"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </el-card>
    <!-- 添加检测数据对话框 -->
    <el-dialog
      :title="title"
      :visible.sync="addQualityTestDialogVisible"
      width="30%"
      @close="clearAddQualityTest"
    >
      <el-form
        :model="qualityTestForm"
        :rules="qualityTestRules"
        ref="QualityTestFormRef"
        label-width="100px"
        class="demo-ruleForm"
      >
        <!-- 商户 -->
        <el-form-item
          label="商户名称"
          prop="merchantId"
        >
          <el-select
            v-model="qualityTestForm.merchantId"
            filterable
            placeholder="请选择商户"
            @change="input"
          >
            <el-option
              v-for="item in shopList"
              :key="item.id"
              :label="item.merchantsInfo.shopName"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <!-- 食品名称 -->
        <el-form-item
          label="食品名称"
          prop="productName"
        >
          <el-select
            v-model="qualityTestForm.productName"
            filterable
            placeholder="请选择食品名称"
          >
            <el-option
              v-for="item in productStandardList"
              :key="item.id"
              :label="item.name"
              :value="item.name"
            >
            </el-option>
          </el-select>
          <!-- <el-input v-model="qualityTestForm.productName" clearable></el-input> -->
        </el-form-item>
        <el-form-item
          label="食品种类"
          prop="productCategoryName"
        >
          <el-select
            v-model="qualityTestForm.productCategoryName"
            placeholder="请选择食品种类"
          >
            <el-option
              v-for="item in productCategoryList"
              :key="item.id"
              :label="item.name"
              :value="item.name"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="检测项目"
          prop="standardId"
        >
          <el-select
            v-model="qualityTestForm.standardId"
            placeholder="请选择检测项目"
          >
            <el-option
              v-for="(item, index) in qualityAtandardList"
              :key="index"
              :label="item.projectName"
              :value="item.id"
            >
              <!-- 这里有Bug,需要等接口调整后，value改为item.id值 -->
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="检测值"
          prop="qualityTestValue"
        >
          <el-input
            v-model="qualityTestForm.qualityTestValue"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item
          label="是否合格"
          prop="result"
        >
          <el-switch
            :active-value="0"
            :inactive-value="1"
            v-model="qualityTestForm.result"
          ></el-switch>
        </el-form-item>
        <el-form-item
          label="检测员"
          prop="surveyor"
        >
          <el-input
            v-model="qualityTestForm.surveyor"
            clearable
          ></el-input>
        </el-form-item>
      </el-form>
      <span
        slot="footer"
        class="dialog-footer"
      >
        <el-button @click="addQualityTestDialogVisible = false">取 消</el-button>
        <el-button
          type="primary"
          @click="submit"
        >确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data () {
    return {
      // 查询食品安全检测数据入参
      queryInfo: {
        limit: 15,
        pageNo: 1,
        merchantId: '',
        productCategoryName: '', // 食品种类
        productName: '', // 食品名称
        marketId: ''
      },
      // 食品安全检测数据列表
      qualityTestList: [],
      // 总数
      total: 0,
      // 添加食品安全检测数据入参
      qualityTestForm: {
        marketId: '',
        merchantId: '',
        productCategoryName: '', // 食品种类
        productName: '', // 食品名称
        qualityTestValue: '', // 检测值
        result: 0, // 检测结果 0-合格 1-不合格
        standardId: '', // 食品安全检测项目id
        surveyor: '' // 检测员
      },
      // 新增对话框，默认false
      addQualityTestDialogVisible: false,
      // 新增和修改公用一个对话框
      title: '',
      submitType: '',
      // 校验规则
      qualityTestRules: {
        merchantId: [
          { required: true, message: '请输入商户名称', trigger: 'blur' }
        ],
        productName: [
          { required: true, message: '请输入食品名称', trigger: 'blur' }
        ],
        productCategoryName: [
          { required: true, message: '请输入食品种类', trigger: 'change' }
        ],
        standardId: [
          { required: true, message: '请选择检测项目', trigger: 'blur' }
        ],
        qualityTestValue: [
          { required: true, message: '请输入检测值', trigger: 'blur' }
        ],
        result: [
          { required: true, message: '请选择是否合格', trigger: 'blur' }
        ],
        surveyor: [{ required: true, message: '请输入检测员', trigger: 'blur' }]
      },
      // 修改所需要的检测数据id
      qualityTestId: '',
      // 检测项目数组
      qualityAtandardList: [],
      // 商品种类数组
      productCategoryList: [],
      // 标准商品列表
      productStandardList: [],
      marketId: '',
      // 商户列表
      shopList: [],
      uploadUrl: '', // 地址
      headers: {
        'admin-authorization': ''
      }
    }
  },
  created () {
    // 取上级路由传送过来的id
    this.queryInfo.merchantId = this.$route.query.id
    // 调用列表接口
    this.getQualityTestList()
    // 调用商品种类请求
    this.getProductCategoryList()
    // 获取标准商品
    this.getProductStandardList()
    this.headers['admin-authorization'] = window.sessionStorage.getItem('token')
  },
  mounted () {
    this.marketId = window.sessionStorage.getItem('currentMarketId')
    this.uploadUrl = `/api/v1/admin/food-test/qualityTest/import?marketId=${this.marketId}`
  },
  methods: {
    searchQualityTestList () {
      this.queryInfo.pageNo = 1
      this.getQualityTestList()
    },
    // 调用食品安全检测数据查询列表
    async getQualityTestList () {
      this.queryInfo.marketId = window.sessionStorage.getItem('currentMarketId')
      const { data: res } = await this.$http.get('food-test/qualityTest/list', {
        params: this.queryInfo
      })
      if (res.code !== 0) {
        return this.$message.error('查看食品安全检测数据失败')
      }
      this.qualityTestList = res.data.data
      this.total = res.data.total
    },
    // limit变化，调用列表接口
    handleSizeChange (newSize) {
      this.queryInfo.limit = newSize
      this.getQualityTestList()
    },
    // 页码变化，调用接口
    handleCurrentChange (newPage) {
      this.queryInfo.pageNo = newPage
      this.getQualityTestList()
    },
    // 打开添加检测数据对话框
    showAddQualityTestDialog () {
      // 赋值marketId
      this.qualityTestForm.marketId = window.sessionStorage.getItem(
        'currentMarketId'
      )
      // 调用检测项目请求
      this.getQualityAtandardList()
      // 调用商品种类请求
      this.getProductCategoryList()
      // 获取商户列表
      this.getShopList()
      // 商户id直接赋值
      this.qualityTestForm.merchantId = this.queryInfo.merchantId
      this.title = '添加检测数据'
      this.submitType = 'add'
      this.addQualityTestDialogVisible = true
    },
    // 打开修改供应商对话框
    showEditQualityTestDialog (row) {
      // 调用检测项目请求
      this.getQualityAtandardList()
      // 调用商品种类请求
      this.getProductCategoryList()
      this.qualityTestForm = row
      this.qualityTestId = row.id
      // 手动赋值选择框内的id值，这里接口有Bug，等修改后再做调试，id随便填的
      this.qualityTestForm.standardId = row.qualityStandardVo.id
      // this.qualityTestForm.standardId = 2
      this.title = '修改检测数据'
      this.submitType = 'edit'
      this.addQualityTestDialogVisible = true
    },
    // 提交请求
    submit () {
      this.$refs.QualityTestFormRef.validate(async valid => {
        if (!valid) return
        // 可以发起网络请求
        if (this.submitType === 'add') {
          const { data: res } = await this.$http.post(
            'food-test/qualityTest/',
            this.qualityTestForm
          )
          if (res.code !== 0) {
            return this.$message.error(res.message)
          }
          this.$message.success('添加检测数据成功')
          this.getQualityTestList()
          this.addQualityTestDialogVisible = false
        } else {
          const { data: res } = await this.$http.put(
            `food-test/qualityTest/${this.qualityTestId}`,
            this.qualityTestForm
          )
          if (res.code !== 0) {
            return this.$message.error(res.message)
          }
          this.$message.success('修改检测数据成功')
          this.getQualityTestList()
          this.addQualityTestDialogVisible = false
        }
      })
    },
    // 关闭对话框
    clearAddQualityTest () {
      this.qualityTestForm = {}
      // 默认合格
      this.qualityTestForm.result = 0
      this.$refs.QualityTestFormRef.resetFields()
      this.getQualityTestList()
    },
    // 定义请求检测项目方法
    async getQualityAtandardList () {
      const { data: res } = await this.$http.get(
        'food-test/qualityStandard/list?limit=200&pageNo=1'
      )
      if (res.code !== 0) {
        return this.$message.error(res.message)
      }
      // 请求成功后进行赋值
      this.qualityAtandardList = res.data.data
    },
    // 定义商品种类方法
    async getProductCategoryList () {
      const { data: res } = await this.$http.get(
        'productCategory/list?limit=500&pageNo=1'
      )
      if (res.code !== 0) {
        return this.$message.error(res.message)
      }
      // 请求成功后进行赋值
      this.productCategoryList = res.data.data
    },
    // 删除操作
    async removeQualityTestById (id) {
      // 弹框提醒
      const confirmResult = await this.$confirm(
        '此操作将永久删除该检测数据，是否继续？',
        '提示',
        {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }
      ).catch(err => err)
      if (confirmResult !== 'confirm') {
        return this.$message.info('已取消删除')
      }
      const { data: res } = await this.$http.delete(
        `food-test/qualityTest/${id}`
      )
      if (res.code !== 0) {
        return this.$message.error('删除检测数据失败')
      }
      this.$message.success('删除检测数据成功')
      this.getQualityTestList()
    },
    // 获取标准商品列表
    async getProductStandardList () {
      const { data: res } = await this.$http.get(
        'productStandard/query?limit=3000&pageNo=1'
      )
      if (res.code !== 0) {
        return this.$message.error(res.message)
      }
      // 请求成功后进行赋值
      this.productStandardList = res.data.data
    },
    // 获取商户列表
    async getShopList () {
      const { data: res } = await this.$http.get(
        `merchants/list?limit=10000&marketId=${this.qualityTestForm.marketId}&pageNo=1`
      )
      if (res.code !== 0) {
        return this.$message.error(res.message)
      }
      // 请求成功后进行赋值
      this.shopList = res.data.data
    },
    // 强制更新
    input () {
      this.$forceUpdate()
    },
    // 导入摊位
    beforeUpload (file) {
      const extension = file.name.substring(file.name.lastIndexOf('.') + 1)
      if (extension !== 'xlsx') {
        this.$message({
          message: '上传文件只能是 xlsx格式!',
          type: 'warning'
        })
        return false
      }
      // this.getBoothList()
    },
    uploadSuccess (res, file, fileList) {
      // console.log(res.code, 111)
      // console.log(res, 11122)
      if (res.code !== 0) {
        this.$message({
          message: '导入失败!',
          type: 'error'
        })
      } else {
        this.$message({
          message: '导入成功!',
          type: 'success'
        })
      }
      this.$refs.upload.clearFiles()
      this.getQualityTestList()
    }
  }
}
</script>

<style lang="less" scoped>
.top-btn {
  padding-bottom: 20px;
  display: flex;
}
.el-col {
  margin-right: 20px;
}
.el-row {
  margin-bottom: 20px;
}
.input {
  display: flex;
  width: 340px;
  align-items: center;
  justify-content: center;
}
.el-input {
  width: 250px;
}
</style>
